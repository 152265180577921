<template>
  <div class="w-full">
    <div class="w-full vs-con-loading__container" id="loadingRetorno">
      <vs-tabs
        position="left"
        color="dark"
        alignment="fixed"
        class="tabs-shadow-none mt-5"
      >
        <vs-tab icon="filter_1" label="PAGOS">
          <div v-if="retornoPago.length == 0" class="scroll-retorno">
            <vs-col
              class="mt-5"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
            </vs-col>
            <vs-col
              class="mt-2"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span>SEM RETORNOS PAGOS</span>
            </vs-col>
          </div>
          <div class="mt-1" v-else>
            <VuePerfectScrollbar class="scroll-retorno" :settings="settings">
              <vs-table
                stripe
                max-items="10"
                pagination
                :data="retornoPago"
                multiple
                search
                color="warning"
                v-model="contasSelecionadas"
              >
                <template slot="header">
                  <vs-row
                    class="my-3"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                  >
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                      vs-w="12"
                    >
                      <vs-button
                        class="mr-5"
                        type="line"
                        :disabled="contasSelecionadas.length == 0"
                        @click="quitarContasSelecionadas()"
                        color="success"
                        size="small"
                      >
                        <b style="font-size: 16px">Quitar</b>
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </template>
                <template slot="thead">
                  <vs-th style="width: 5%" id="center">Nota</vs-th>
                  <vs-th style="width: 20%" id="center">Cliente</vs-th>
                  <vs-th style="width: 18%" id="center">Valor</vs-th>
                  <vs-th style="width: 12%" id="center">Mov. Anteriores</vs-th>
                  <vs-th style="width: 10%" id="center">Juros</vs-th>
                  <vs-th style="width: 12%" id="center">Data Crédito</vs-th>
                  <vs-th style="width: 12%" id="center">Data Ocorrência</vs-th>
                  <vs-th style="width: 12%" id="center">Data Vencimento</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="index"
                    :data="conta"
                    v-for="(conta, index) in data"
                    :style="
                      conta.status_conta == 4
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : conta.status_conta == 3
                        ? 'color:#00BBA2;background-color: #dff2f0 !important'
                        : ''
                    "
                  >
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ conta.nota ? conta.nota : "--" }}</b>
                    </vs-td>
                    <vs-td v-if="conta.id_cliente">
                      <VisualizarCliente
                        :id_cliente="conta.id_cliente"
                        :nomeCliente="conta.nome_fantasia"
                        fontSize="small"
                      />
                    </vs-td>
                    <vs-td class="text-center" v-else style="font-size: 12px"
                      >--</vs-td
                    >
                    <vs-td
                      class="text-center"
                      :style="
                        conta.valor != conta.valor_pago
                          ? 'color: red; font-size: 12px'
                          : 'black; font-size: 12px'
                      "
                    >
                      <b>
                        {{ $currency(conta.valor_pago) }} de
                        {{ $currency(conta.valor) }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.valor_pago_movimento) }}</b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          $currency(
                            parseFloat(conta.juros_mora) +
                              parseFloat(conta.juros_multa || 0)
                          ) || "0.00"
                        }}
                      </b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{ conta.data_credito ? conta.data_credito : "--" }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_ocorrencia ? conta.data_ocorrencia : "--"
                        }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_vencimento ? conta.data_vencimento : "--"
                        }}
                      </b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </vs-tab>
        <vs-tab icon="filter_2" label="PENDENTES">
          <div v-if="retornoPendente.length == 0" class="scroll-retorno">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                class="mt-5"
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
              </vs-col>
              <vs-col
                class="mt-2"
                vs-w="12"
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
              >
                <span>SEM RETORNOS PENDENTES</span>
              </vs-col>
            </vs-row>
          </div>
          <div class="mt-1" v-else>
            <VuePerfectScrollbar class="scroll-retorno" :settings="settings">
              <vs-table
                stripe
                max-items="10"
                search
                pagination
                :data="retornoPendente"
              >
                <template slot="thead">
                  <vs-th style="width: 5%" id="center">Nota</vs-th>
                  <vs-th style="width: 20%" id="center">Cliente</vs-th>
                  <vs-th style="width: 18%" id="center">Valor</vs-th>
                  <vs-th style="width: 12%" id="center">Mov. Anteriores</vs-th>
                  <vs-th style="width: 10%" id="center">Juros</vs-th>
                  <vs-th style="width: 12%" id="center">Data Crédito</vs-th>
                  <vs-th style="width: 12%" id="center">Data Ocorrência</vs-th>
                  <vs-th style="width: 12%" id="center">Data Vencimento</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="index"
                    :data="conta"
                    v-for="(conta, index) in data"
                    :style="
                      conta.status_conta == 4
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : conta.status_conta == 3
                        ? 'color:#00BBA2;background-color: #dff2f0 !important'
                        : ''
                    "
                  >
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ conta.nota ? conta.nota : "--" }}</b>
                    </vs-td>
                    <vs-td v-if="conta.id_cliente">
                      <VisualizarCliente
                        :id_cliente="conta.id_cliente"
                        :nomeCliente="conta.nome_fantasia"
                        fontSize="small"
                      />
                    </vs-td>
                    <vs-td class="text-center" v-else style="font-size: 12px"
                      >--</vs-td
                    >
                    <vs-td
                      class="text-center"
                      :style="
                        conta.valor != conta.valor_pago
                          ? 'color: red; font-size: 12px'
                          : 'black; font-size: 12px'
                      "
                    >
                      <b>
                        {{ $currency(conta.valor_pago) }} de
                        {{ $currency(conta.valor) }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.valor_pago_movimento) }}</b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          $currency(
                            parseFloat(conta.juros_mora) +
                              parseFloat(conta.juros_multa)
                          )
                        }}
                      </b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{ conta.data_credito ? conta.data_credito : "--" }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_ocorrencia ? conta.data_ocorrencia : "--"
                        }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_vencimento ? conta.data_vencimento : "--"
                        }}
                      </b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </vs-tab>

        <vs-tab icon="filter_3" label="INVÁLIDOS">
          <div v-if="retornoInvalido.length == 0" class="scroll-retorno">
            <vs-col
              class="mt-5"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
            </vs-col>
            <vs-col
              class="mt-2"
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <span>SEM RETORNOS INVÁLIDOS</span>
            </vs-col>
          </div>
          <div class="mt-1" v-else>
            <VuePerfectScrollbar class="scroll-retorno" :settings="settings">
              <vs-table
                stripe
                max-items="10"
                pagination
                search
                :data="retornoInvalido"
              >
                <template slot="thead">
                  <vs-th style="width: 5%" id="center">Nota</vs-th>
                  <vs-th style="width: 20%" id="center">Cliente</vs-th>
                  <vs-th style="width: 18%" id="center">Valor</vs-th>
                  <vs-th style="width: 12%" id="center">Mov. Anteriores</vs-th>
                  <vs-th style="width: 10%" id="center">Juros</vs-th>
                  <vs-th style="width: 12%" id="center">Data Crédito</vs-th>
                  <vs-th style="width: 12%" id="center">Data Ocorrência</vs-th>
                  <vs-th style="width: 12%" id="center">Data Vencimento</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="index"
                    :data="conta"
                    v-for="(conta, index) in data"
                    :style="
                      conta.status_conta == 4
                        ? 'color: green;background-color:#e8fce8 !important;'
                        : conta.status_conta == 3
                        ? 'color:#00BBA2;background-color: #dff2f0 !important'
                        : ''
                    "
                  >
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ conta.nota ? conta.nota : "--" }}</b>
                    </vs-td>
                    <vs-td v-if="conta.id_cliente">
                      <VisualizarCliente
                        :id_cliente="conta.id_cliente"
                        :nomeCliente="conta.nome_fantasia"
                        fontSize="small"
                      />
                    </vs-td>
                    <vs-td class="text-center" v-else style="font-size: 12px"
                      >--</vs-td
                    >
                    <vs-td
                      class="text-center"
                      :style="
                        conta.valor != conta.valor_pago
                          ? 'color: red; font-size: 12px'
                          : 'black; font-size: 12px'
                      "
                    >
                      <b>
                        {{ $currency(conta.valor_pago) }} de
                        {{ $currency(conta.valor) }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>{{ $currency(conta.valor_pago_movimento) }}</b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          $currency(
                            parseFloat(conta.juros_mora) +
                              parseFloat(conta.juros_multa)
                          )
                        }}
                      </b>
                    </vs-td>

                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{ conta.data_credito ? conta.data_credito : "--" }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_ocorrencia ? conta.data_ocorrencia : "--"
                        }}
                      </b>
                    </vs-td>
                    <vs-td class="text-center" style="font-size: 12px">
                      <b>
                        {{
                          conta.data_vencimento ? conta.data_vencimento : "--"
                        }}
                      </b>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </VuePerfectScrollbar>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="6"
        >
          <vs-button
            @click="showTotalPago = !showTotalPago"
            class="mx-2"
            type="line"
            :disabled="blockButton"
            color="success"
            size="small"
          >
            <span style="font-size: 14px" class="font-bold"
              >Pagos: {{ retornoPago.length }}</span
            >
          </vs-button>
          <vs-button
            :disabled="blockButton"
            class="mx-2"
            type="line"
            color="primary"
            size="small"
          >
            <span style="font-size: 14px" class="font-bold"
              >Pendentes: {{ retornoPendente.length }}</span
            >
          </vs-button>
          <vs-button
            :disabled="blockButton"
            class="mx-2"
            type="line"
            color="danger"
            size="small"
          >
            <span style="font-size: 14px" class="font-bold"
              >Inválidos: {{ retornoInvalido.length }}</span
            >
          </vs-button>
        </vs-col>
        <vs-col vs-w="5" vs-justify="flex-end" vs-align="flex-end">
          <div class="w-full">
            <vs-row
              vs-type="flex"
              class
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-w="12"
            >
              <vs-col vs-w="8" vs-justify="flex-end" vs-align="flex-end">
                <div class="mt-1 mx-2">
                  <b-form-file
                    placeholder="Escolha um Arquivo"
                    id="file-small"
                    size="md"
                    @change="previewFiles"
                  ></b-form-file>
                </div>
              </vs-col>
              <vs-col vs-w="3" vs-justify="flex-end" vs-align="flex-end">
                <vs-button
                  icon="send"
                  class="mx-4"
                  type="line"
                  :disabled="blockButton || !file"
                  @click="enviar()"
                  color="success"
                  size="small"
                >
                  <b style="font-size: 16px">Enviar</b>
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card class="mt-2" no-shadow card-background="dark" v-if="showTotalPago">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="2.5"
          class
        >
          <vs-button line-origin="left" color="white" type="line" size="small">
            <span style="font-size: 12px; font-weight: 600">
              {{ "Valor Total: " + $currency(valorPagoTotalizador.valorTotal) }}
            </span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="2"
        >
          <vs-button line-origin="left" color="white" type="line" size="small">
            <span style="font-size: 12px; font-weight: 600">
              {{ "Juros: " + $currency(valorPagoTotalizador.juros) }}
            </span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="2.5"
          class
        >
          <vs-button line-origin="left" color="white" type="line" size="small">
            <span style="font-size: 12px; font-weight: 600">
              {{
                "Valor Esperado: " +
                $currency(valorPagoTotalizador.valorEsperado)
              }}
            </span>
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import VisualizarCliente from "./../../../cliente/visualizar.vue"

export default {
  data () {
    return {
      file: null,
      showInvalid: false,
      contasSelecionadas: [],

      retornoQuitado: [],
      retornoPago: [],
      retornoPendente: [],
      retornoInvalido: [],

      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.6,
        height: 1000
      },

      blockButton: false,
      showTotalPago: false,
      valorPagoTotalizador: { valorTotal: 0, valorEsperado: 0, juros: 0 }
    }
  },
  methods: {
    async quitarContasSelecionadas () {
      this.blockButton = true
      await this.$vs.loading({ container: "#loadingRetorno", scale: 0.6 })
      try {
        await this.$http.post(`quitarContasRetorno`, {
          contas: this.contasSelecionadas
        })
        this.contasSelecionadas = []
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#loadingRetorno > .con-vs-loading")
        await this.enviar()
        this.blockButton = false
      }
    },
    async voltar () {
      this.$router.push("/financeiro")
    },
    async enviar () {
      this.showTotalPago = false
      this.blockButton = true
      await this.$vs.loading({ container: "#loadingRetorno", scale: 0.6 })
      let formData = new FormData()
      formData.append("file", this.file)
      try {
        const res = await this.$http.post(`retorno`, formData)
        this.retornoQuitado = []
        this.retornoPago = []
        this.retornoPendente = []
        this.retornoInvalido = []
        this.valorPagoTotalizador.valorTotal = 0
        this.valorPagoTotalizador.valorEsperado = 0
        this.valorPagoTotalizador.juros = 0

        res.forEach(obj => {
          if (obj.status_pago == 1 && obj.status_id_conta == 1) {
            this.retornoPago.push(obj)
            this.valorPagoTotalizador.valorTotal += parseFloat(
              obj.valor_pago || 0
            )
            this.valorPagoTotalizador.juros +=
              parseFloat(obj.juros_mora || 0) +
              parseFloat(obj.juros_multa || 0)
            this.valorPagoTotalizador.valorEsperado += parseFloat(
              obj.valor || 0
            )
          } else if (obj.status_pago == 0 && obj.status_id_conta == 1) {
            this.retornoPendente.push(obj)
          } else {
            this.retornoInvalido.push(obj)
          }
        })
        this.retornoPago.sort((a, b) =>
          a.status_conta > b.status_conta
            ? 1
            : b.status_conta > a.status_conta
              ? -1
              : 0
        )
        this.retornoPendente.sort((a, b) =>
          a.status_conta > b.status_conta
            ? 1
            : b.status_conta > a.status_conta
              ? -1
              : 0
        )
        this.retornoInvalido.sort((a, b) =>
          a.status_conta > b.status_conta
            ? 1
            : b.status_conta > a.status_conta
              ? -1
              : 0
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#loadingRetorno > .con-vs-loading")
        this.blockButton = false
      }
    },
    previewFiles (event) {
      this.file = event.target.files[0]
    }
  },
  components: {
    ...components,
    VisualizarCliente
  }
};
</script>
<style lang="scss">
.scroll-retorno {
  height: 80vh !important;
}
.scroll-main {
  height: 80vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.con-slot-tabs {
  width: 100%;
}
.con-tab-ejemplo {
  width: 100%;
}
</style>
